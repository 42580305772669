import { useEffect, useState } from "react";
import styles from "./App.module.css";
import Form from "./components/Form";
import PaymentSuccessful from "./components/PaymentSuccessFul";
import useKlashaPayment from "./libs/use-klasha";
import { ReactComponent as LogoPink } from "./assets/icons/loadericon.svg";
import { ReactComponent as LogoIcon } from "./assets/icons/logo.svg";
import { theAvailablePayingCurrencies } from "./utils/availableCurrencies";
import { v4 as uuidv4 } from "uuid";

const whitelistedOrigins = [
  "https://stitch-redirect.herokuapp.com",
  "https://stitch-redirect-o542m.ondigitalocean.app",
  "https://secure.stitch.money",
  "https://plugins-redirect.klasha.com",
];

function App() {
  const [email, setEmail] = useState("");
  const [payCurrency, setPayCurrency] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentSucceeded, setPaymentSucceeded] = useState(false);
  const [payCurrencyWithoutIndex, setPayCurrencyWithoutIndex] = useState("");
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const onClear = () => {
    setEmail("");
    setAmount("");
    setPayCurrency("");
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [1000]);
  }, [loading]);

  const removePaymentGatewayFromDOM = () => {
    const gatewayPopup = document.getElementById("klashaPayment");
    gatewayPopup.remove();
  };

  useEffect(() => {
    // listen to stitch payment response
    window.addEventListener("message", (ev) => {
      if (whitelistedOrigins.includes(ev.origin)) {
        if (typeof ev.data !== "object") return;
        if (!ev.data.type) return;
        if (ev.data.type !== "klasha:stitch") return;
        if (!ev.data.status) return;
        callWhenDone(ev.data);
        removePaymentGatewayFromDOM();
      }
    });
  }, []);

  useEffect(() => {
    if (payCurrency && payCurrency.length) {
      const payCurrencyWithoutI = payCurrency.split("-")[0];
      setPayCurrencyWithoutIndex(payCurrencyWithoutI);
    }
  }, [payCurrency]);

  const onSubmit = async (data) => {
    if (!email.match(mailformat)) {
      setEmailError("Please enter a valid email address");
    } else {
      initializePayment();
    }
  };

  const callWhenDone = (data) => {
    if (data["status"] === "successful") {
      setSuccessMessage("Payment successful");
      setPaymentSucceeded(true);
    } else if (data["status"] === "pending") {
      setSuccessMessage(
        "Your payment is now being processed. You will be notified via email when payment is successful."
      );
      setPaymentSucceeded(true);
    } else {
      setPaymentSucceeded(false);
    }
  };

  const transactionId = uuidv4();

  const initializePayment = useKlashaPayment({
    isTestMode: process.env["REACT_APP_ENV"] === "dev",
    email: email,
    merchantKey:
      "GN1gmENvEPXxH6QcjmQgsDbaQAT/HzVYcMq6GG769rF3kFhTJol7Ixb5MhpIGPTyscJWjY29OxUBCHI+Sj+4rg==",
    amount: amount.replace(/,/g, ""),
    destinationCurrency: payCurrencyWithoutIndex,
    sourceCurrency: payCurrencyWithoutIndex,
    tx_ref: transactionId,
    kit: {
      currency: payCurrencyWithoutIndex,
      tx_ref: transactionId,
      email: email,
      callBack: callWhenDone,
      paymentType: "demo",
    },
    paymentDescription: "",
    channel: "paylink",
  });

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [loading]);
  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.header}>
          <LogoIcon />
        </div>
        {loading && (
          <div className={styles.loaderContainer}>
            <div className={styles.spin}>
              <div className={styles.spinner_logo}>
                <LogoPink />
              </div>
              <div className={styles.spinner}> </div>
            </div>
          </div>
        )}

        {paymentSucceeded && !loading && (
          <PaymentSuccessful message={successMessage} />
        )}

        {!paymentSucceeded && !loading && (
          <Form
            email={email}
            payCurrency={payCurrency}
            thePayingCurrencies={theAvailablePayingCurrencies}
            amount={amount}
            setEmail={setEmail}
            setPayCurrency={setPayCurrency}
            setAmount={setAmount}
            onSubmit={onSubmit}
            onClear={onClear}
            errorText={emailError}
            onFocus={() => setEmailError("")}
          />
        )}

        <div className={styles.footer}>
          <p>
            Email: <a href="mailto:support@klasha.com">support@klasha.com</a>
          </p>
          <p>
            WhatsApp: <a href="https://wa.me/+12138630549">+1 (213) 863-0549</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
