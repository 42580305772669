export const theAvailablePayingCurrencies = [
  {
    flag: "",
    currency: "KES",
  },
  {
    flag: "",
    currency: "NGN",
  },
  {
    flag: "",
    currency: "TZS",
  },
  {
    flag: "",
    currency: "UGX",
  },
  {
    flag: "",
    currency: "USD",
  },
  {
    flag: "",
    currency: "ZAR",
  },
  {
    flag: "",
    currency: "ZMW",
  },
];
