import styles from '../../styles/Form.module.css';
import NumberFormat from "react-number-format"

const AmountInput = ({ value, setValue, field, dataContent, onFocus }) => {
    const onChangeValue = (values) => {
        const { formattedValue, value } = values
        setValue(formattedValue)
    }
    return (
        <div className={styles.formInputContainer}>
            <div className={styles.formInput} data-content={dataContent} onFocus={onFocus}
            >
                <NumberFormat
                    thousandSeparator={true}
                    value={value}
                    onValueChange={onChangeValue}
                    {...field}
                    decimalSeparator={"."}
                />
            </div>
        </div>

    )
}

export default AmountInput;
