import styles from '../../styles/Form.module.css';

const FormInput = ({ value, setValue, inputType = 'text', pattern, field, dataContent, errorMessage, onFocus }) => {


	const onChangeValue = (e) => {
		field.onChange(e)
		setValue(e.target.value)
	}

	return (
		<div className={styles.formInputContainer}>
			<div className={styles.formInput} data-content={dataContent} onFocus={onFocus}
			>
				<input
					value={value}
					type={inputType}
					pattern={pattern}
					{...field}
					onChange={onChangeValue}
				/>
			</div>
			{
				errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>
			}
		</div>

	)
}

export default FormInput;
