import { useForm, Controller } from "react-hook-form";
import styles from '../../styles/Form.module.css';
import { ReactComponent as LogoWhiteIcon } from "../../assets/icons/logo-light.svg";
import FormInput from "../FormInput";
import PayingCurrencySelect from "../CurrencySelect/PayingCurrency";
import AmountInput from "../AmountInput";

function Form({ email, setEmail, payCurrency, setPayCurrency, thePayingCurrencies, amount, setAmount, onSubmit, onClear, errorText, onFocus }) {
	const { control, handleSubmit, formState: { errors }, reset } = useForm();
	const onCancelClick = () => {
		reset({
			email: '',
			amount: '',
		})
		onClear()
	}
	return (
		<div className={styles.form}>
			<div className={styles.formSubHead}>
				<p>Enter your work email address and a dummy amount to view the checkout.</p>
			</div>

			<form className={styles.formBody} onSubmit={handleSubmit(onSubmit)}>

				<Controller
					name="email"
					control={control}
					rules={{
						required: true,
					}}
					render={({ field }) => (
						<FormInput
							value={email}
							dataContent='Email address'
							inputType={'text'}
							setValue={setEmail}
							field={field}
							errorMessage={errors.email?.type === "required" ? "Email is required" : errorText}
							onFocus={onFocus} />
					)}
				/>


				<div className={styles.formDiv}>
					<PayingCurrencySelect dataContent='Paying currency' options={thePayingCurrencies} value={payCurrency}
						setValue={setPayCurrency} />

					<Controller
						name="amount"
						control={control}
						rules={{
							required: true,
						}}
						render={({ field }) => (
							<AmountInput value={amount} dataContent='Amount' setValue={setAmount} field={field} inputType="number" />
							// <FormInput
							// 	value={amount}
							// 	dataContent='Amount'
							// 	inputType={'number'}
							// 	setValue={setAmount}
							// 	field={field}
							// 	errorMessage={errors.amount?.type === "required" && "Amount is required"}
							// />
						)}
					/>


				</div>

				<button type='submit' className={styles.payBtn} disabled={!payCurrency?.length || !email?.length || !amount?.length}>
					<span>Pay with </span>
					<LogoWhiteIcon className={styles.payBtnLogo} />
				</button>
				<button type='button' className={styles.cancelBtn} onClick={onCancelClick}>
					Cancel
				</button>
			</form>
		</div>
	);
}

export default Form;
