import styles from '../../styles/Form.module.css';

const PayingCurrencySelect = ({ options = [], disabled, value, setValue, dataContent }) => {
	return (
		<div className={styles.formInputContainer}>
			<div className={styles.formInput} data-content={dataContent}>
				<select
					required={true}
					className={styles.selectInputField}
					disabled={disabled} value={value}
					onChange={(e) => setValue(e.target.value)}
				>
					<option value="">Select</option>
					{
						options.map((opt, index) => {
							return (
								<option key={index} value={`${opt.currency}-${index}`}>
									{`${opt.flag} ${opt.currency}`}
								</option>
							)
						})
					}
				</select>
			</div>
		</div>
	)
}

export default PayingCurrencySelect;
